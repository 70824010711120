import React from 'react';
import { Heading, Text, IconButton } from '@atoms';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useStaticQuery, graphql, Link } from 'gatsby';
import { HeroContainer, HomeCrumb } from './styles/HeroNewsroomListing.styled'
import OptimizedImage from "../../utils/optimizedImage"

const HeroNewsListing = (component) => {
  const newsroomListingHeroData = useStaticQuery(graphql`
    query newsroomListingHeroQuery {
      contentfulComponentHero(type: {eq: "newsroom-listing"}) {
        id
        background
        callsToAction {
          id
          internalLink
          internalName
          label
          link
          type
          variant
        }
        featuredImage {
          description
          gatsbyImageData
          file {
            url
          }
          title
        }
        heading
        headingSize
        kicker
        subheading {
          raw
        }
        type
      }
    }
  `)

  const { heading, headingSize, kicker, subheading, callsToAction, featuredImage, background } = newsroomListingHeroData?.contentfulComponentHero

  return (
    <HeroContainer
      data-comp={HeroNewsListing.displayName}
    >
      <OptimizedImage image={featuredImage?.gatsbyImageData} src={featuredImage?.file?.url} alt="" loading="eager" className="hero-image"/>
      <div className="hero-news-listing--wrapper">
        <div className="hero-news-listing--overlay" />
        <div className="hero-news-listing--burger">
          <Link style={{ textDecoration: 'none' }} href="/"><HomeCrumb id='blog-listing--home-crumb' style={{ textDecoration: 'none', color: 'white', transition: 'color 200ms ease' }}>Home</HomeCrumb></Link>
          <img src='/icons/chevron-right-white.svg' />
          <div style={{ fontWeight: 700, cursor: 'pointer' }}>Newsroom</div>
        </div>
        <div className="hero-news-listing--content">
          <div className="hero-news-listing--content-wrapper">
            {kicker && <Text textVariant='light' className="hero-news-listing--kicker">{kicker}</Text>}
            {heading && <Heading headingSize={headingSize} background='dark' className="hero-news-listing--heading">{heading}</Heading>}
            { subheading?.raw && <Text textVariant='light' className='hero-news-listing--subheading'>{documentToReactComponents(JSON.parse(subheading?.raw))}</Text>}
            {callsToAction[0] && <IconButton ctaVariant='secondary' ctaLink={callsToAction[0]?.link}>{callsToAction[0]?.label}</IconButton>}
          </div>
        </div>
      </div>
    </HeroContainer>
  );
};

export default HeroNewsListing;
HeroNewsListing.displayName = 'HeroNewsListing';
